import pigIcon from '../../assets/pig.png';
import bankIcon from '../../assets/bank.png';
import aptIcon from '../../assets/apt.png';

const services = [
  {
    icon: pigIcon,
    title: "Property Discovery",
    description: "Tell us what you're looking for, and our advanced search will tailor properties to your specific needs.",
  },
  {
    icon: bankIcon,
    title: "AI Offer Negotiation",
    description: "Let our AI help you craft the perfect deal by factoring in key details like market trends, inspection reports, disclosures, and more.",
  },
  {
    icon: pigIcon,
    title: "Home Inspection",
    description: "Count on us to quickly schedule your home inspection, ensuring your property is thoroughly checked and ready.",
  },
  {
    icon: aptIcon,
    title: "Tours",
    description: "Schedule in-person private tours with ease, whether you're looking for a cozy home or urban apartment.",
  },
  {
    icon: pigIcon,
    title: "Legal Paperwork",
    description: "We take care of all the attorney documentation, streamlining the legal process so you can focus on your new home.",
  },
  {
    icon: aptIcon,
    title: "Mortgage Loan",
    description: "Get the best mortgage loan options, tailored to your financial needs and home-buying goals.",
  },
];

export default services;