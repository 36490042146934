import React from 'react';
import './LandingScreen.css';
import homepageImage from '../../assets/homepage.png';

function LandingScreen() {
  return (
    <div>
      <div className="home-container" style={{ backgroundImage: `url(${homepageImage})` }}>
        <div className="home-content">
          {/* <h1 className="landing-title">ROCQS <span className="landing-ai-gradient">AI</span></h1> */}
          <h1 className="landing-title">ROCQS</h1>
          <p className="landing-subtitle">
            <span className="landing-highlight-gradient">skip agent fees.</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingScreen;