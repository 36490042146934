import React from 'react';
import Box from '../Box/Box.js';
import services from './ServiceData.js'; 
import './Grid.css';  

const Grid = () => {
    return (
        <div className="service-container">
          <div className="service-boxes">
            {services.map((service, index) => (
              <Box
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
                className={`box box-${index + 1}`}
              />
            ))}
          </div>
        </div>
      );
    };

export default Grid;