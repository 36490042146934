import React from 'react';
import './Timeline.css';

const steps = [
  { number: 1, title: "Curate your", subtitle: "Dream Homes" },
  { number: 2, title: "Schedule", subtitle: "Tours" },
  { number: 3, title: "Submit", subtitle: "Offer" },
  { number: 4, title: "Connect with", subtitle: "Mortgage Lenders" },
  { number: 5, title: "Home", subtitle: "Inspection" },
  { number: 6, title: "Re-Negotiate", subtitle: "Offer" },
  { number: 7, title: "Attorney", subtitle: "Review" },
  { number: 8, title: "Sale", subtitle: "Closing" },
];

const Timeline = () => {
  return (
    <div className="timeline-container">
      <h2 className="timeline-title">
        Your Transaction, <span>all in one place</span>
      </h2>
      <div className="timeline">
        {steps.map((step) => (
          <div className="timeline-step" key={step.id}>
            <div className="timeline-number">{step.number}</div>
            <div className="timeline-content">
              <span>{step.title}</span> <span className="timeline_sub">{step.subtitle}</span>
            </div>
          </div>
        ))}
        <div className="timeline-connector"></div>
      </div>
    </div>
  );
};

export default Timeline;