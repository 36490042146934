import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navigation from './components/Navigation/Navigation';
import LandingScreen from './components/LandingScreen/LandingScreen';
import Waitlist from './components/Waitlist/Waitlist';
import FAQ from './components/FAQ/FAQ';
import Descr from './components/Descr/Descr';
import Timeline from './components/Timeline/Timeline';
import Grid from './components/Grid/Grid';
import Footer from './components/Footer/Footer';
import SocialMediaPlatter from './components/SocialMediaPlatter/SocialMediaPlatter';
import Contact from './components/Contact/Contact';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Navigation />
    <LandingScreen />
    <Descr />
    <Grid />
    < Waitlist />
    <Timeline />
    <Contact />
    {/* Add About Us Section Here */}
    <FAQ />
    {/* <SocialMediaPlatter /> */}
    <Footer />



  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
