import React from 'react';
import './Descr.css';
import break_line from '../../assets/break_line.png';

const Descr = () => {
  return (
    <section className="descr-section">
      <h2 className="descr-title">
        Buy your Next Home with <span className="highlight-text">Confidence</span>
      </h2>
        <img src={break_line} alt="break line" className="break-line" />
      <p className="descr-paragraph">
        At ROCQS AI, we believe that finding the perfect home should be an exciting and effortless journey. 
        Our AI-powered platform seamlessly guides you through every step — from discovering your dream home 
        and negotiating offers to closing the deal — with full transparency and no hidden fees.
      </p>
      <p className="descr-paragraph emphasis">
        And the best part? <strong>Pay 0% in Realtor Commissions.</strong>
      </p>
    </section>
  );
};

export default Descr;