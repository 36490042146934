import React from 'react';
import './Box.css';
import breakLine from '../../assets/break_line.png';

const Box = ({ icon, title, description }) => {
  return (
    <div className="box">
      <img src={icon} alt={`${title} icon`} className="box-icon" />
      <h3 className="box-title">{title}</h3>
      <p className="box-description">{description}</p>
      <img src={breakLine} alt="break line" className="break-line-box" />
  </div>
  );
};

export default Box;