import React, { useState } from "react";
import "./Waitlist.css";
import waitlistImage from "../../assets/waitlist.png";

export default function Waitlist() {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      alert("Please enter a valid email.");
      return;
    }

    try {
      const response = await fetch("http://localhost:8080/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      alert("Thank you for joining the waitlist!");
      if (response.ok) {
        setEmail("");
      } else {
        alert("Failed to join the waitlist. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <section
      className="waitlist-section"
      style={{ backgroundImage: `url(${waitlistImage})` }}
      id="waitlist" 
    >
      <div className="waitlist-content">
        <h2 className="waitlist-title">Coming Soon! Join our Waitlist</h2>
        <p className="subtitle-form">
          Get notified when you can save big on your next home
        </p>

        <form onSubmit={handleSubmit} className="waitlist-form">
          <input
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="waitlist-email-input"
          />
          <button type="submit" className="waitlist-submit-button">
            Subscribe
          </button>
        </form>
      </div>
    </section>
  );
}