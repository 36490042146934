import React from "react";
import "./Navigation.css";
// import logo from "../../assets/logo.png";
import logo from "../../assets/logo_transparent.png";

function Navigation() {
  const smoothScroll = (target, duration) => {
    const targetPosition = target.getBoundingClientRect().top;
    const startPosition = window.pageYOffset;
    let startTime = null;

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, targetPosition, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
  };
  // const scrollToWaitlist = (e) => {
  //     e.preventDefault();
  //     const waitlistSection = document.getElementById('waitlist');
  //     if (waitlistSection) {
  //         waitlistSection.scrollIntoView({ behavior: 'smooth' });
  //     }
  // }
  // const scrollToContact = (e) => {
  //     e.preventDefault();
  //     const contactSection = document.getElementById('contact');
  //     if (contactSection) {
  //         contactSection.scrollIntoView({ behavior: 'smooth' });
  //     }
  // }
  const scrollToWaitlist = (e) => {
    e.preventDefault();
    const waitlistSection = document.getElementById("waitlist");
    if (waitlistSection) {
      smoothScroll(waitlistSection, 1500);
    }
  };
  const scrollToContact = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      smoothScroll(contactSection, 1500);
    }
  };
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <ul className="navbar-links">
        <li>
          <a href="#waitlist" onClick={scrollToWaitlist}>
            WAITLIST
          </a>
        </li>
        <li>
          <a href="#contact" onClick={scrollToContact}>
            CONTACT
          </a>
        </li>
        <li>
          <a href="#about">ABOUT US</a>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
