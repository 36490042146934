import React from "react";
import "./Contact.css";
import contactImage from "../../assets/contact.png";
import breakLine from "../../assets/break_line.png";

const ContactForm = () => {
    return (
      <div className="contact-section" style={{ backgroundImage: `url(${contactImage})` }} id="contact">
        <div className="contact-content">
          <div className="contact-left">
            <h2>Get in <span className="highlight">touch</span></h2>
            <img src={breakLine} alt="break line" className="break-line" />
            <p>
              Have more questions about our product? Fill out this form and a member of our team will be in touch with you shortly.
            </p>
            <p>Make sure to check out our Q&A section to see if your question was already answered!</p>
          </div>
          <div className="contact-form">
            <form>
              <div className="contact-form-group">
                <label htmlFor="name">Full name *</label>
                <input type="text" id="name" placeholder="Enter your name" />
              </div>
              <div className="contact-form-group">
                <label htmlFor="email">Email address *</label>
                <input type="email" id="email" placeholder="Enter your email" />
              </div>
              <div className="contact-form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" rows="4" placeholder="Enter your message"></textarea>
              </div>
              <button type="submit" className="contact-submit-btn">Submit</button>
            </form>
          </div>
        </div>
      </div>
    );
  };
  
  export default ContactForm;