import React from 'react';
import './FAQ.css';

const FAQ = () => {
  return (
    <section className="faq-section">
      <h2 className="faq-title">Common <span className="faq-highlight">questions</span></h2>
      <div className="faq-content">
        <div className="faq-column">
          <div className="faq-item">
            <h3>How does home buying work?</h3>
            <p className="faq-desc">You can browse listings, schedule tours, and handle all paperwork and inspections all on our website. Our platform directly connects you with the experts you need — whether it's getting a mortgage loan or conducting an attorney review — we’ve got you covered!</p>
          </div>

          <div className="faq-item">
            <h3>Are there any hidden fees or costs?</h3>
            <p className="faq-desc">Nope! All costs are upfront and clear. All rates for expert help — like an attorney or home inspector — are priced transparently.</p>
          </div>

          <div className="faq-item">
            <h3>How secure is my personal and financial information?</h3>
            <p className="faq-desc">Security is our top priority. We use advanced encryption technologies and enforce strict privacy standards to make sure sensitive information stays confidential.</p>
          </div>
        </div>

        <div className="faq-column">
          <div className="faq-item">
            <h3>How does ROCQS AI save me money?</h3>
            <p className="faq-desc">Real estate agents are motivated to sell high and fast. By cutting them out, you avoid paying high commission fees. Our platform equips you with expert advice and advanced property valuation tools needed to get the best deal.</p>
          </div>

          <div className="faq-item">
            <h3>What part of this is AI?</h3>
            <p className="faq-desc">Our AI analyzes factors like location, market trends, and property disclosures to give expert advice on your offer. It also suggests renegotiation strategies with reasoning, including price adjustments, after reviewing the inspection report.</p>
          </div>

          <div className="faq-item">
            <h3>What’s the difference between this and Zillow or Redfin?</h3>
            <p className="faq-desc">Unlike the others, we offer a fully integrated buying experience. Now close on your dream house faster and receive the house keys right in your mailbox. No brokers, no hidden fees.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;